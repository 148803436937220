<template>
  <div class="grouph_istory">
    <div class="grouph_istory_home">
      <div class="grouph_istory_img">
        <img
          src="../../newImage/history.png"
          style="width: 1005px; height: 248px"
          alt=""
          srcset=""
        />
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-align: center;
        "
      >
        <div
          v-for="(item, index) in getlist"
          :key="index"
          class="grouph_istory_dom"
        >
          <div
            style="
              text-align: left;
              display: flex;
              align-items: center;
              margin: 45px 0;
            "
          >
            <span class="year">{{ item.year }} </span
            ><img
              class="triangle"
              src="../../newImage/triangle.png"
              alt=""
              srcset=""
            /><span class="month">{{ item.month }}月</span>
          </div>
          <div class="grouph_istory_list_dom">
            <div class="hen_gang"></div>
            <div class="grouph_istory_box" @click="onjump(item)">
              <div class="grouph_istory_box_font">
                <div class="one">{{ item.title }}</div>
                <div class="two"></div>
                <div class="three">
                  {{ item.content }}
                </div>
              </div>
              <div class="grouph_istory_box_img">
                <img :src="item.img" alt="" class="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 100px"></div>
      <!-- <div class="all">
        <div class="font_all" @click="getAll">
          点击加载更多<i class="el-icon-arrow-right"></i>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "grouph_istory",

  data() {
    return {
      getlist: [
        {
          year: "2024",
          month: "12",
          content:
            "新环境、新起点、新征程。静待春晓，蓄势待发，12月15日是一个值得纪念的日子，浙江中峪控股集团旗下上海各企业——中御绿产企业管理中心、中御绿证企业服务中心、中御绿智企业发展中心正式入驻上海中国金融信息中心，开业大吉！在各级领导的高度重视和支持下，举行了隆重的开业仪式。未来，在集团统一领导下，上海各企业将携手共进、潮头逐浪，共启新篇章。",
          title: "中峪集团旗下各企业入驻中国金融信息中心",
          img: require("../../../static/newImage/grouph_istory/5.jpg"),
        },
        {
          year: "2023",
          month: "3",
          content:
            "2023.3.3日，新昌县镇相关领导一行莅临中峪集团总部，就道地药材种植基地落户新昌沃洲镇合作事宜，与中峪集团董事长深入洽谈。董事长向县镇领导详细介绍了中峪集团各产业版块的内在逻辑和实施闭环，并就双方关心的道地药材种植基地落户沃洲镇，展开了深入的洽谈。新昌县镇领导从新昌当地的相关扶持政策、合作平台、土地流转、环境交通、配套设施以及振兴当地乡村经济建设等多个方面与董事长进行了磋商，承诺当地政府会全力配合中峪集团道地药材产业种植基地的各项落地工作，并对基地后续带来的集体经济创收、文旅文化产业、科研版块等进行了展望。对中峪道地药材产业数字化发展高度赞赏，并提出进一步合作意向。",
          title: "中峪集团农业版块正式启动",
          img: require("../../../static/newImage/grouph_istory/4.jpg"),
        },
        {
          year: "2022",
          month: "10",
          content:
            "视拓是浙江鑫亮生物科技有限责任公司旗下品牌。是一家以专注近视、弱视、斜视、远视等视力预防控制为主，采用中医核心秘方，结合科学物理视训，独创“物灸渗控”四位一体疗法，坚持不入眼、无任何副作用，面向所有群体的眼部养护服务机构，正积极朝向专业的中医眼科医院迈进。首家加盟店的落地运营，标志着鑫亮视拓已开启新篇章，迎接新辉煌。未来更多的视拓加盟店将在全国各地发芽生根，必将早日实现中医眼科医院、中医医院，造福百姓、造福社会。",
          title: "中峪集团视拓版块郑重按下开启键",
          img: require("../../../static/newImage/grouph_istory/3.jpg"),
        },
        {
          year: "2022",
          month: "4",
          content:
            "2022年4月2日，中峪职业技能培训学校正式落地柯桥。学校审批手续的全面落地，标志着中峪九大闭环产业又向前迈出了坚实的一步。中峪职业技能培训学校是中峪集团（筹）旗下的文化战略版块，是九大产业的重要一环。学校肩负着给集团产业链培养出更多针对性人才的重任，是人才培养的孵化基地，将为其他战略版块源源不断输送专业人才和管理团队，为在闭环生态链内开拓出更多粘性市场服务。同时，中峪职业技能培训学校的出现，也将为地方职业教育和就业贡献自己的力量。",
          title: "中峪集团学校版块落地柯桥",
          img: require("../../../static/newImage/grouph_istory/2.jpg"),
        },
        {
          year: "2019",
          month: "5",
          content:
            "浙江中峪控股集团有限公司 是一家涉足商贸、拍卖交易所、职业培训学校、中医大健康、医疗、生物制药、网络科技、养老产业、金融等十大闭环产业的集团化公司，集团以国家政策为导向，以大众创业，万众创新为初心，跨界融合、科技创新、数字经济、跨产业赋能、多元化经营、服务于各生产企业的同时，为市场培育孵化更多优质的人才。同时紧跟国家战略部署，以乡村振兴为战略目标；以传承弘扬发展中医文化为使命，把中医精髓代代相传并发扬光大，普惠大众；以振兴地区职业技能，培育多元化技术人才，助推就业问题；为国家大健康战略规划真正落地生根普及，为提升老年人生活品质等民生领域，贡献自己的力量。",
          title: "中峪控股集团成立",
          img: require("../../../static/newImage/grouph_istory/1.jpg"),
        },
      ],
    };
  },

  mounted() {
    let scrollTop = window.scrollY || document.documentElement.scrollTop;
    console.log("当前垂直滚动位置：", scrollTop);
  },

  methods: {
    onjump(item) {
      console.log(item);
    },
    getAll() {
      // const list = [{}, {}, {}];
      // this.getlist.push(...list);
      console.log("all");
    },
  },
};
</script>
<style lang="scss" scoped>
.grouph_istory {
  min-width: 1600px;
  text-align: center;

  .grouph_istory_home {
    background: url("../../newImage/grouph_istory.jpg");
    padding: 0 175px;
    background-size: contain;
    background-repeat: no-repeat;
    .grouph_istory_img {
      text-align: center;
      padding-top: 180px;
      margin-bottom: 200px;
    }
    .grouph_istory_dom {
      width: 1360px;

      .year {
        width: 165px;
        height: 76px;
        display: inline-block;
        background: linear-gradient(
          289deg,
          #e7152d 0%,
          #c31224 25%,
          #e9182a 71%,
          #c51722 100%
        );
        border-radius: 8px;
        font-weight: bold;
        font-size: 45px;
        text-align: center;
        line-height: 76px;
        color: #ffe6bb;
      }
      .triangle {
        margin: 0 20px;
        width: 17px;
        height: 22px;
      }
      .month {
        font-weight: bold;
        font-size: 44px;
        padding-bottom: 7px;
        color: #c51722;
      }
      .grouph_istory_list_dom {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .hen_gang {
        width: 2px;
        height: 380px;
        background: linear-gradient(
          180deg,
          rgba(228, 51, 71, 0) 0%,
          #d52534 50%,
          rgba(197, 23, 34, 0) 100%
        );
        opacity: 0.54;
      }
      .grouph_istory_box {
        background-image: url("../../newImage/01411c12a8d314ee57191a4051f0853.png"),
          linear-gradient(
            94deg,
            #ffe6e6 0%,
            #ffe6e6 5%,
            #fffefe 25%,
            #fffefe 100%
          );
        // background-size: contain;
        min-height: 300px;
        margin-left: 45px;
        display: flex;
        background-repeat: round;
        // overflow: hidden;
        width: 1122px;
        border-radius: 5px;
        box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);
        justify-content: space-between;
        align-items: center;
        .grouph_istory_box_font {
          width: 618px;
          margin: 38px 0;
          margin-left: 48px;
          .one {
            font-weight: 800;
            font-size: 27px;
            color: #1d1b19;
            text-align: left;
            line-height: 34px;
          }
          .two {
            width: 303px;
            margin-top: 23px;
            margin-bottom: 11px;
            height: 3px;
            background: linear-gradient(
              270deg,
              rgba(255, 217, 217, 0) 0%,
              #ffd3d3 100%
            );
          }
          .three {
            font-weight: 400;
            font-size: 14px;
            color: #555762;
            line-height: 29px;
            letter-spacing: 1px;
            text-align: justify;
          }
        }
        .grouph_istory_box_img {
          width: 385px;
          height: 212px;
          margin-top: 45px;
          margin-right: 34px;
          margin-bottom: 20px;
          // background: #ffd3d3;
          border: 4px solid #dea56a;
          .img {
            width: 385px;
            height: 212px;
          }
        }
      }
    }
    .all {
      height: 106px;
      background: linear-gradient(
        270deg,
        rgba(220, 196, 196, 0) 0%,
        rgba(218, 199, 183, 0.24) 49%,
        rgba(218, 189, 189, 0) 100%
      );
      //   opacity: 0.3;
      margin: 85px 0;
      text-align: center;
      font-weight: bold;
      font-size: 34px;
      line-height: 106px;
      color: #c51722;
      .font_all {
        cursor: pointer;
        font-weight: bold;
        font-size: 34px;
        color: #c51722;
        // opacity: 1 !important;
      }
    }
  }
}
</style>
